Px.CMS.fetchAndMorphAbortController = new AbortController();

Px.CMS.fetchAndMorph = function(options) {
  options = options || {};
  // Abort any currently pending fetch.
  Px.CMS.fetchAndMorphAbortController.abort();
  const controller = new AbortController();
  Px.CMS.fetchAndMorphAbortController = controller;

  const pxNoRerenderValue = (element) => {
    if ('pxNoRerender' in element) {
      return element.pxNoRerender;
    } else if (element.dataset && 'pxNoRerender' in element.dataset) {
      return element.dataset.pxNoRerender;
    }
  };

  fetch(window.location.toString(), {signal: controller.signal}).then(response => {
    response.text().then(text => {
      const new_doc = new DOMParser().parseFromString(text, 'text/html');
      // Collect all live fragments in the updated doc.
      const updated_elements = {};
      Px.Util.walkDOMElements(new_doc.body, element => {
        const fragment_id = element.getAttribute('data-px-live-fragment');
        if (fragment_id) {
          updated_elements[fragment_id] = element;
        }
      });
      // TODO: In liquid CMS we morph the entire page, ignoring data-px-live-fragment attributes.
      //       Split the liquid-specific code out into a separate script when/if we create a new
      //       CMS JS bundle.
      if (Object.keys(updated_elements).length > 0) {
        // Walk the current document and update any live fragments.
        Px.Util.walkDOMElements(document.body, element => {
          const fragment_id = element.getAttribute('data-px-live-fragment');
          if (fragment_id) {
            const updated_element = updated_elements[fragment_id];
            if (updated_element) {
              morphdom(element, updated_element, {
                onBeforeElUpdated: (from_el, to_el) => {
                  if (pxNoRerenderValue(from_el.dataset) === 'true') {
                    return false;
                  }
                  // Never morph src attributes of mapped preview images. They are dynamically rendered
                  // via canvas as Blob URIs. We have MutationObservers that will repaint the image if
                  // the data in data-px-mapped-preview changes.
                  if (from_el.getAttribute('data-px-mapped-preview')) {
                    to_el.setAttribute('src', from_el.getAttribute('src'));
                  }
                },
                onBeforeNodeDiscarded: (node) => {
                  if (pxNoRerenderValue(node) === 'true') {
                    return false;
                  }
                },
                onBeforeElChildrenUpdated: (from_el, to_el) => {
                  if (pxNoRerenderValue(from_el) === 'children') {
                    return false;
                  }
                }
              });
            } else {
              element.remove();
            }
            return false;
          }
        });
        // Remove the px-live-fragment-loading class from all forms.
        document.querySelectorAll('.px-live-fragment-loading').forEach(element => {
          element.classList.remove('px-live-fragment-loading');
        });
      } else if (Array.isArray(options.selectors)) {
        options.selectors.forEach(selector => {
          const updated_element = new_doc.body.querySelector(selector);
          const existing_element = document.body.querySelector(selector);
          if (existing_element) {
            if (updated_element) {
              morphdom(existing_element, updated_element, {
                onBeforeElUpdated: (from_el, to_el) => {
                  if (pxNoRerenderValue(from_el) === 'true') {
                    return false;
                  }
                },
                onBeforeNodeDiscarded: (node) => {
                  if (pxNoRerenderValue(node) === 'true') {
                    return false;
                  }
                },
                onBeforeElChildrenUpdated: (from_el, to_el) => {
                  if (pxNoRerenderValue(from_el) === 'children') {
                    return false;
                  }
                }
              });
            } else {
              existing_element.remove();
            }
          }
        });
      } else {
        morphdom(document.body, new_doc.body, {
          onBeforeElUpdated: (from_el, to_el) => {
            if (pxNoRerenderValue(from_el) === 'true') {
              return false;
            }
          },
          onBeforeNodeDiscarded: (node) => {
            if (pxNoRerenderValue(node) === 'true') {
              return false;
            }
          },
          onBeforeElChildrenUpdated: (from_el, to_el) => {
            if (pxNoRerenderValue(from_el) === 'children') {
              return false;
            }
          }
        });
      }
      if (options.callback) {
        options.callback();
      }
      window.dispatchEvent(new Event('px.fragmentsReloaded'));
    });
  });
};
